import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';

export class Direcciones extends Component{

    render (){
        return (
            <div style={{ width:300, float:'left' ,  height:300, paddingLeft:'8%' }}>
                <Row>
                    <Col sm={12}>
                        <pre style={{fontFamily:'Raleway',color:'#444444'}}>Oficina NUEVO LEÓN<br/><br/>
                            
                            Paras 850 Int. 1003, Barrio Antiguo, Centro,<br/>
                            Monterrey, N.L., C.P. 64000<br/>
                        </pre>
                        <br/><br/>
                        <pre style={{fontFamily:'Raleway',color:'#444444'}}>Oficina COLIMA<br/><br/>
                            
                            Ave. Ayuntamiento 605 Local 10,<br/>
                            Villa de Álvarez, Colima, C.P. 28979<br/>
                        </pre>
                    </Col>
                </Row>
                <br/><br/><br/><br/><br/>
            </div>
        )
    }
} export default Direcciones
                                
                           