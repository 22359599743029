import React, { Component } from 'react';
import {Label, Input, Button, Row, Col} from 'reactstrap';
import Mapa from './mapa';
import Direcciones from './direcciones';
export class Contacto extends Component{
   

    enviaMensaje = () =>{
			
        let data = {
            nombre: document.getElementById("txtNombre").value,
            mensaje: document.getElementById("txtMensaje").value,
            email: document.getElementById("txtEmail").value,
            celular: document.getElementById("txtCelular").value  //this.state.modelEmpleado.noempl
        };
    
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        // console.log(data);
        fetch('https://adhome.mx/services/adhome_agregaMensaje.php', requestInfo)
        .then(response => response.json())
        .then(respuesta => {
            if(respuesta.id==='y'){
                alert('Gracias por contactarnos, en breve nos comunicaremos con usted');
                document.getElementById("txtNombre").value = '';
                document.getElementById("txtMensaje").value='';
                document.getElementById("txtEmail").value='';
                document.getElementById("txtCelular").value='';

            }
        })
        .catch(e=>console.log(e)) 

    }

    render (){

    return (

    <div  className="container" style={{width:'100%'}}>
		
				  <div  style={{ textAlign:'center',fontFamily: 'Raleway', fontSize:'24px', color:'#444444', zIndex:5}}>
				  <h3 style={{paddingLeft:50}}>CONTACTO</h3>
				  </div>
				  <div style={{float:"left",paddingLeft:'9%'}}>
					  <Row>
						  <Col sm={3}>
							  <Row>
								  <Col sm={12}>
									  <Label style={{fontFamily: 'Raleway', fontSize:'16px'}}>
										  Nombre
									  </Label>
								  </Col>
								  <Col sm={12}>
									  <Input id="txtNombre" type="text" style={{width:250,fontFamily: 'Raleway', fontSize:'16px'}}></Input>
								  </Col>
							  </Row>
							  <Row>
								  <Col sm={12}>
									  <Label style={{fontFamily: 'Raleway', fontSize:'16px'}}>
										  Mensaje
									  </Label>
								  </Col>
								  <Col sm={12}>
									  <Input id="txtMensaje" type="textarea" style={{width:250, height:100 ,fontFamily: 'Raleway', fontSize:'16px'}}></Input>
								  </Col>
							  </Row>
							  <Row>
								  <Col sm={12}>
									  <Label style={{fontFamily: 'Raleway', fontSize:'16px'}}>
										  Email
									  </Label>
								  </Col>
								  <Col sm={12}>
									  <Input id="txtEmail" type="text" style={{width:250,fontFamily: 'Raleway', fontSize:'16px'}}></Input>
								  </Col>
							  </Row>
							  <Row>
								  <Col sm={12}>
									  <Label style={{fontFamily: 'Raleway', fontSize:'16px'}}>
										  Celular
									  </Label>
								  </Col>
								  <Col sm={12}>
									  <Input id="txtCelular" type="text" style={{width:250,fontFamily: 'Raleway', fontSize:'16px'}}></Input>
								  </Col>
							  </Row>
							  <br/>
							  <Row >
								  <Col sm={12} style={{textAlign:'left'}}>
								  <Button onClick={this.enviaMensaje.bind(this)} style={{height:30,fontFamily: 'Raleway', fontSize:16}} outline color='secondary'>  Enviar </Button>
								  </Col>
							  </Row>
						  </Col>
                        </Row> 
				  </div>
                 <Direcciones />
                 <Mapa />
				
				
			</div>
        )
    }
} export default Contacto