import React, { Component } from 'react';
import { Jumbotron} from 'reactstrap';

export class Vender extends Component{
    render(){
        
        return(
            <div className="container" style={{textAlign:"center" , width:'100%'}}>
            <Jumbotron>
                         <h1 className="display-6">¿Quieres vender tu propiedad?</h1> <br/>
                         <p className="lead" style={{fontSize:16,textAlign:"center",paddingLeft:'9%',paddingRight:'9%'}}>Agencia Inmobiliaria con experiencia en el ramo, invierte en propiedades en el área metropolitana.<br/><br/>

                         No importa en qué condiciones esté, si la está pagando, quiere traspasarla o si está intestada.<br/><br/>

                         Solicite una cita y mande su información para tenerle una propuesta en firme lo antes posible.</p>
                        <br/>
                        <img hidden={false} src="https://adhome.mx/services/adhome_imagenes/vender.jpg" alt='adHome' />
                        <br/>
                        <br/>
                       
                     </Jumbotron>
                     <br/><br/><br/><br/><br/>
        </div>
        )
    }
}
export default Vender