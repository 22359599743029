import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';

export class Mapa extends Component{
    static defaultProps = {
        center: {lat: 22.534032, lng: -101.753150},
        zoom: 5 
     }
     render (){

        return (
            <div>
                <div style={{ width:300, float:'left' ,height:300, paddingLeft:'8%' }}>
						  
						  <GoogleMapReact
						  bootstrapURLKeys={{
							 // key: 'AIzaSyBixQeRWGTe40laWtG6AV8M1rMcRebwg_o',  
							 key: 'AIzaSyB_eF_QESksS9uxSVM0znVswQ2TdaRq0gk',  		 
							  language: 'es'
						  }}
						  defaultCenter={this.props.center}
						  center={this.props.center}
						  defaultZoom={this.props.zoom}
						  options={{disableDefaultUI: true,
							  mapTypeControl: true,
							  streetViewControl: true,
							  }}
						  >
						  
							  <Marker
							  lat={25.667554}
							  lng={-100.312795}
							  name={'Ad Home Oficina Nuevo León'}
							  color="red"
							  
							  />

                            <Marker
							  lat={19.2293816}
							  lng={-103.6867437}
							  name={'Ad Home Oficina Colima'}
							  color="red"
							  
							  />
						  
						  </GoogleMapReact>
					  </div>	
            </div>
        )
     }
} export default Mapa