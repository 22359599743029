import React, { Component } from 'react';
import { Label, Jumbotron} from 'reactstrap';

export class Acercade extends Component{
    render(){
        
        return(
            <div className="container" style={{textAlign:"center" }}>
            <Jumbotron>
                         <h1 className="display-6">Acerca de nostros </h1> <br/>
                         <p className="lead" style={{fontSize:14,textAlign:"justify",paddingLeft:'9%',paddingRight:'9%'}}>Acerca de nosotros
AdHome es una empresa joven, con experiencia en el giro inmobiliario de más de 14 años. Iniciados desde 2006 en empresas importantes de la región con presencia nacional, (Casas Ara, Grupo COI, Casas Geo, Sinergia Constructiva), inicialmente nuestra labor fue la Gerencia de Ventas en dichas empresas y posteriormente el desarrollar soluciones para vender desarrollos con problemáticas especificas que impedían una venta fluida de sus prototipos.<br/><br/>

Adicionalmente la empresa busca desarrollar Sistemas de Desarrollo Comunitarios amigables con el entorno de los fraccionamientos habitacionales en el estado, a través de la administración, control y Operación de Servicios de Vigilancia, Limpieza, Jardinería, Funcionalidad del Reglamento Interno de cada Fraccionamiento y Establecer una relación con el municipio para las mejoras continuas y Gestión ante la autoridad.<br/><br/>

Nuestra razón de ser es el servicio y atención a USTED, sus NECESIDADES son el motor que nos mueve a ofrecerles mejores soluciones..</p>
                        <br/>
                        <img hidden={window.innerWidth<580?true:false} src="https://adhome.mx/services/adhome_imagenes/acercaimagen.jpg" alt='adHome' />
                        <br/>
                        <br/>
                        <Label hidden={window.innerWidth<580?true:false} style={{color:'black', fontWeight:'bolder',fontSize:20}}>
                            ¿Parecen iguales?, pero en la calidad del servicio nos distinguimos, para el que vende y para el que adquiere.
                        </Label>
                        
                     </Jumbotron>
                     <br/><br/><br/><br/><br/>
        </div>
        )
    }
}
export default Acercade