
import React, { Component } from 'react';
import {Card, CardImg} from 'reactstrap';
import Nav from "./nav";
import Contacto from './contacto';
import Propiedades from './propiedades';
import Acercade from './acercade';
import Bienvenidos from './bienvenidos';
import Creditos from './creditos';
import Vender from './vender';
import AdminProp from './adminprop';


class App extends Component {

	
	state = {
		isMobile: false,
		mytop:0,
		modulo:'',
		submodulo:'',
		ventaorenta:'',
		etiqueta:'¿Que estas buscando?',
		propiedadid:'',
		showpdf:false,
		numPages:0,
		pageNumber:0
	}

	onWindowResize = () => {
		this.setState({ isMobile: window.innerWidth < 580 });
		if(this.state.isMobile ===true){
			this.setState({mytop:140})
		}else{
			this.setState({mytop:90})
		}

	}
	
	showPropiedadesVenta = () =>{
		this.setState({etiqueta:'Propiedades en Venta'});
		this.setState({modulo:'propiedades'});
		this.setState({submodulo:''});
		this.setState({ventaorenta:'Venta'});
	}

	showPropiedadesRenta = () =>{
		this.setState({etiqueta:'Propiedades en Renta'});
		this.setState({modulo:'propiedades'});
		this.setState({submodulo:''});
		this.setState({ventaorenta:'Renta'});
	}

	showInicio = () =>{
		console.log('voy inicio');
		this.setState({etiqueta:'¿Que estas buscando?'})
		this.setState({modulo:'inicio'})
		this.setState({submodulo:'bienvenidos'})
	}

	showAcerca = () =>{
		this.setState({etiqueta:'¿Que estas buscando?'})
		this.setState({modulo:'inicio'})
		this.setState({submodulo:'acerca'})
	}

	showVender = () =>{
		this.setState({etiqueta:'¿Que estas buscando?'})
		this.setState({modulo:'inicio'})
		this.setState({submodulo:'vender'})
	}

	showCreditos = () =>{
		this.setState({etiqueta:'¿Que estas buscando?'});
		this.setState({modulo:'inicio'});
		this.setState({submodulo:'creditos'});
	}

	showAdminProp = () =>{
		this.setState({etiqueta:'¿Que estas buscando?'})
		this.setState({modulo:'inicio'})
		this.setState({submodulo:'adminprop'})
	}

	showContacto = () =>{
		this.setState({etiqueta:'¿Que estas buscando?'})
		this.setState({modulo:'inicio'})
		this.setState({submodulo:'contacto'})
	}

	goPropiedad = (propiedadid) =>{
		this.setState({propiedadid:propiedadid});
		let data = {
			propiedadid : propiedadid
		}
		console.log(data);
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
         console.log(data);
        fetch('https://adhome.mx/services/adhome_getPropiedadxId.php', requestInfo)
        .then(response => response.json())
        .then(respuesta => {
			console.log(respuesta);
			if(respuesta[0].tipo ==='Venta'){
				this.setState({etiqueta:'Propiedades en Venta'});
				this.setState({modulo:'propiedades'});
				this.setState({submodulo:''});
				this.setState({ventaorenta:'Venta'});
			}else{
				this.setState({etiqueta:'Propiedades en Renta'});
				this.setState({modulo:'propiedades'});
				this.setState({submodulo:''});
				this.setState({ventaorenta:'Renta'});
			}
        })
        .catch(e=>console.log(e)) 

		
	}

	

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	};

	goToPrevPage = () =>
		this.setState((state) => ({ pageNumber: state.pageNumber - 1 }));
	goToNextPage = () =>
		this.setState((state) => ({ pageNumber: state.pageNumber + 1 }));
	
	getQueryVariable = (variable) => {
		// Estoy asumiendo que query es window.location.search.substring(1);
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		// alert(vars);
		for (var i=0; i < vars.length; i++) {
			var pair = vars[i].split("="); 
			if (pair[0] === variable) {
				return pair[1];
			}
		}
		return false;
	}
	
	componentDidMount() {
		
		window.addEventListener('resize', this.onWindowResize);
		this.onWindowResize();
		if(this.getQueryVariable('id') !== false){
			let quer = this.getQueryVariable('id');
			console.log(quer);
			this.goPropiedad(quer);
        }else{
			this.showInicio();
			
    	}   
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.onWindowResize);
	}

	render() {
		return (
		<div >
			{/* Navegador correcto */}
			<Nav showInicio={this.showInicio.bind(this)} showContacto={this.showContacto.bind(this)} showAcerca={this.showAcerca.bind(this)} showCreditos={this.showCreditos.bind(this)}/>
				
			{/* Panel blanco superior correcto */}
			<div className="containt fluid" style={{height:250, flex:'row', width:'100%', backgroundColor:'white', position:'absolute', top:0, zIndex:5}}/>
			
				{/* Logo de empresa correcto */}
				
					<div  style={{ width:300, position:'absolute', top:window.innerWidth<580?140:90, paddingLeft:'3%', zIndex:5}}>
						<img style={{cursor:'pointer'}} onClick={this.showInicio.bind(this)}  width={window.innerWidth<580?'200':'300'} src='https://adhome.mx/services/adhome_imagenes/logo_adHome.jpg' alt='adHome'/>
					</div>
				

				{/* Contenedor oscuro con letrero correcto*/}
				<div  className="containt fluid" style={{height:70, flex:'row', width:'100%', backgroundColor:'rgba(44,44,44,.9)', position:'absolute', top:250, zIndex:2}}>
					<h1 style={{textAlign:'center', paddingTop:7,  color:'white', fontFamily: 'Raleway'}}>{this.state.etiqueta}</h1>
				</div>

				{/* Tarjetas correcto*/}
				
				
				{ 	this.state.modulo==='inicio'?
				<div style={{top:380, position:'absolute', zIndex:5, width:'100%', display:'flex', justifyContent:'center'}}>
				
					<Card className= 'card text-center' style={{backgroundColor:'white',cursor:'pointer', float:'left', margin:10}}>
						<div onClick={this.showPropiedadesVenta.bind(this)} className="overflow">
							<CardImg width="100%" src={'https://adhome.mx/services/adhome_imagenes/foto1.jpg'} className='card-img-top'  alt="Card image cap" />
						</div>
					</Card>
					<Card  className= 'card text-center'  style={{backgroundColor:'white',cursor:'pointer', float:'left', margin:10}}>
						<div onClick={this.showPropiedadesRenta.bind(this)} className="overflow">
							<CardImg width="100%" src={'https://adhome.mx/services/adhome_imagenes/foto2.jpg'} className='card-img-top'  alt="Card image cap" />
						</div>
					</Card>

					<Card  className= 'card text-center'  style={{backgroundColor:'white',cursor:'pointer', float:'left', margin:10}}>
						<div onClick={this.showVender.bind(this)} className="overflow">
							<CardImg width="100%" src={'https://adhome.mx/services/adhome_imagenes/foto3.jpg'} className='card-img-top' alt="Card image cap" />
						</div>
					</Card>

					<Card  className= 'card text-center'  style={{backgroundColor:'white',cursor:'pointer', float:'left', margin:10}}>
						<div onClick={this.showAdminProp.bind(this)} className="overflow">
						<	CardImg width="100%" src={'https://adhome.mx/services/adhome_imagenes/foto4.jpg'} className='card-img-top'  alt="Card image cap" />
						</div>
					</Card>
					
				
				
				</div>
				:this.state.modulo==='propiedades'?
				
						<div style={{top:300, position:'absolute', zIndex:5, width:'100%'}}>
							<Propiedades ventaorenta={this.state.ventaorenta} propiedadid={this.state.propiedadid}/>
						</div>
				:''
			}
			{
				this.state.submodulo==='bienvenidos'?
					<div style={{paddingTop:700}}>
						<Bienvenidos/>
					</div>
					:
					this.state.submodulo==='acerca'?
					<div style={{paddingTop:700}}>
						<Acercade />
					</div>
					:
					this.state.submodulo==='creditos'?
					<div style={{paddingTop:700}}>
						<Creditos />
					</div>
					:
					this.state.submodulo==='vender'?
					<div style={{paddingTop:700}}>
						<Vender />
					</div>
					:
					this.state.submodulo==='adminprop'?
					<div style={{paddingTop:700}}>
						<AdminProp />
						
					</div>
					:
					this.state.submodulo==='contacto'?
					<div style={{paddingTop:700}}>
						<Contacto />
						
					</div>
					:''

				}
				
			</div>
		);
	}
} export default App;
   