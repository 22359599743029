import React, { Component } from 'react';
import { Jumbotron } from 'reactstrap';

export class AdminProp extends Component{
    render(){
        
        return(
            <div className="container" style={{textAlign:"center" , width:'100%'}}>
            <Jumbotron>
                <h1 className="display-6">Administración de Propiedades </h1> <br/>
               
               <br/>
               <img hidden={false} src="https://adhome.mx/services/adhome_imagenes/imagen1.jpg" alt='adHome' />
               <br/>
               <img hidden={false} src="https://adhome.mx/services/adhome_imagenes/imagen2.jpg" alt='adHome' />
            </Jumbotron>
            <br/><br/><br/><br/><br/>
        </div>
        )
    }
}
export default AdminProp