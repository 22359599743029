import React, { Component} from 'react';
import { Input, InputGroup, Button,Label, Row} from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import NumberFormat from 'react-number-format';
import ConsultaPropiedad from './consultapropiedad';
import CarruselDetalle from './carrusel_detalle';
import { animateScroll as scroll } from 'react-scroll';



export class Propiedades extends Component{
    
    static defaultProps = {
        center: {lat: 24.589103, lng: -102.743727},
        zoom: 5 
    }
    
    state={
        estadosactivosLs:[],
        municipiosactivosLs:[],
        casasLs: [],
        fotosLs:[],
        curdatosLs:[],
        showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      curcasa:{},
      detalle: false,
      curindex:0,
      pasadas:0

    }

    goDown = () =>{
        console.log('Aqui debo ir hacia abajo');
     // console.log(el.scrollHeight);
      scroll.scrollTo(820);
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
          selectedPlace: props.place_,
          activeMarker: marker,
          showingInfoWindow: true
        });
      };

      showDetails = place => {
        console.log(place);
      };

    getCasas = ()=>{
        let data = {
            ventaorenta :this.props.ventaorenta,
            cbestado : document.getElementById("txtCBEstado").checked,
            cbmunicipio : document.getElementById("txtCBMunicipio").checked,
            cbtipopropiedad : document.getElementById("txtCBTipopropiedad").checked,
            cbminimo : document.getElementById("txtCBMinimo").checked,
            cbmaximo : document.getElementById("txtCBMaximo").checked,
            cbrecamaras : document.getElementById("txtCBRecamaras").checked,    
            estadoid : document.getElementById("txtEstadoid").value,
            municipioid : document.getElementById("txtMunicipioid").value,
            tipopropiedad : document.getElementById("txtTipopropiedad").value,
            valmin : document.getElementById("txtMinimo").value,
            valmax : document.getElementById("txtMaximo").value,
            recamaras : document.getElementById("txtRecamaras").value


            
        };
        console.log(data);
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
         console.log(data);
        fetch('https://adhome.mx/services/adhome_getCasas.php', requestInfo)
        .then(response => response.json())
        .then(casasLs => {
        console.log(casasLs);
          
                this.setState({ casasLs });
                
                if(this.props.propiedadid !=='' && this.state.pasadas===0){
                    this.getCasasxId(this.props.propiedadid);
                    this.setState({pasadas:1});
                }
               
           
        })
        .catch(e=>console.log(e)) 
    }


    getCasasxId = (id)=>{
        let data = {
            propiedadid :id
        };
        console.log(data);
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
         console.log(data);
        fetch('https://adhome.mx/services/adhome_getCasaxId.php', requestInfo)
        .then(response => response.json())
        .then(casa => {
        console.log(casa);
          
                
                    this.getDetalle(casa[0]);
                
               
        })
        .catch(e=>console.log(e)) 
    }
    
   

    getFotos = (propiedadidx)=>{
        let data = {
            propiedadid: propiedadidx  //this.state.modelEmpleado.noempl
        };
        console.log(data.propiedadid);
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
         console.log(data);
        fetch('https://adhome.mx/services/adhome_getFotosp.php', requestInfo)
        .then(response => response.json())
        .then(fotosLs => {
        console.log(fotosLs);
          
                this.setState({ fotosLs });
                this.setState({fotoscargadas:true});
           
        })
        .catch(e=>console.log(e)) 
        this.goDown();
    }

    getEstadosActivos = ()=>{
    
        const requestInfo={
            method:'POST',
            //body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        // console.log(data);
        fetch('https://adhome.mx/services/adhome_getEstadosActivos.php', requestInfo)
        .then(response => response.json())
        .then(estadosactivosLs => {
        console.log(estadosactivosLs);
          
                this.setState({ estadosactivosLs });
               this.getMunicipiosActivos();
           
        })
        .catch(e=>console.log(e)) 
    }
    
    getMunicipiosActivos = ()=>{
        let data = {
            estadoid: document.getElementById("txtEstadoid").value  //this.state.modelEmpleado.noempl
        };
        console.log(data.estadoid);
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
         console.log(data);
        fetch('https://adhome.mx/services/adhome_getMunicipiosActivos.php', requestInfo)
        .then(response => response.json())
        .then(municipiosactivosLs => {
        console.log(municipiosactivosLs);
          
                this.setState({ municipiosactivosLs });
               this.getCasas();
           
        })
        .catch(e=>console.log(e)) 
    }


    getDetalle = (casa) =>{
        this.getFotos(casa.id);
        this.setState({curcasa:casa});
        this.setState({detalle:true})
    }

    goRegresa = () =>{
        this.setState({detalle:false});
    }

    cambiaIndex =(curin)=>{
        this.setState({curindex:curin})
    }

    componentDidMount(){
        this.getEstadosActivos();
       
    }

    render (){
       const spacer4={margin:5}
        return (
            <div>
                <div style={{position:'absolute',paddingLeft:25, lineHeight:1.5, width:200, top:90, zIndex:10, backgroundColor:'RGBA(255,255,255,.5)'}}>
                
                <Row style={{paddingLeft:20}}>
                    <Label check  style={{fontSize:14}}>
                        <Input id="txtCBEstado"  type="checkbox" style={{ fontSize:16, fontWeight:'bold'}}/>
                        Estado
                    </Label>
                </Row>
                <Row style={{paddingLeft:20}}>    
                    <Input bsSize='sm' style={{fontSize:12, width:160, backgroundColor:'RGBA(255,255,255,.3)'}} onChange={this.getMunicipiosActivos.bind(this)} id="txtEstadoid" type='select'>
                        {
                            this.state.estadosactivosLs.map(pos => (
                                <option key={pos.id} value={pos.id}>{pos.estado}</option>
                            ))
                        }
                    </Input>
                </Row>
                
                <Row style={{paddingLeft:20, paddingTop:10}}>
                    <Label check  style={{fontSize:14}}>
                        <Input id="txtCBMunicipio" type="checkbox" style={{ fontSize:16, fontWeight:'bold'}}/>
                        Municipio
                    </Label>
                </Row>
                <Row style={{paddingLeft:20}}>        
                                { 
                                    this.state.municipiosactivosLs.length>0?
                                <Input bsSize='sm' style={{fontSize:12, width:160, backgroundColor:'RGBA(255,255,255,.3)'}}  id="txtMunicipioid"  type="select">
                                {
                                this.state.municipiosactivosLs.map(pos => (
                                            <option key={pos.id} value={pos.id}>{pos.municipio}</option>
                                            ))

                                }     
                                </Input>:''
                                }
                </Row>
            
                <Row style={{paddingLeft:20, paddingTop:10}}>
                       
                    <Label check style={{fontSize:14}}>
                        <Input id="txtCBTipopropiedad" type="checkbox" style={{ fontSize:10, fontWeight:'bold'}}/>
                        Tipo de propiedad
                    </Label>
                </Row>
                <Row style={{paddingLeft:20}}>          
                        <Input bsSize='sm' id="txtTipopropiedad" style={{fontSize:12, width:160, backgroundColor:'RGBA(255,255,255,.3)'}} type="select">
                        {  [{tipoprop:'Casa'},{tipoprop:'Casa en condominio'},{tipoprop:'Departamento'},{tipoprop:'Local'},{tipoprop:'Local comercial'},{tipoprop:'Oficina'},{tipoprop:'Quinta'},{tipoprop:'Terreno'},{tipoprop:'Terreno comercial'}].map((pos,index) => (
                                        <option key={index} value={pos.tipoprop}>{pos.tipoprop}</option>
                                        ))}
                        </Input>
                        
                    </Row>
                  
                <Row style={{paddingLeft:20, paddingTop:10}}>
                <Label check style={{fontSize:14}}>
                        <Input id="txtCBMinimo" bsSize='sm' type="checkbox" style={{ fontSize:10, fontWeight:'bold'}}/>
                        Valor mínimo
                    </Label>
                    </Row>
                <Row style={{paddingLeft:20}}>  
                        <Input bsSize='sm' style={{fontSize:12, width:150, backgroundColor:'RGBA(255,255,255,.3)' }} type="text" id="txtMinimo" />
                </Row>
              
                <Row style={{paddingLeft:20, paddingTop:10}}>
                    <Label check style={{fontSize:14}}>
                        <Input id="txtCBMaximo" bsSize='sm' type="checkbox" style={{ fontSize:13, fontWeight:'bold'}}/>
                        Valor máximo
                    </Label>
                    </Row>
                <Row style={{paddingLeft:20}}>      
                        <Input bsSize='sm' style={{fontSize:12, width:150, backgroundColor:'RGBA(255,255,255,.3)' }} type="text" id="txtMaximo" />
                </Row>
             
                <Row style={{paddingLeft:20, paddingTop:10}}>
                <Label check style={{fontSize:14}}>
                        <Input id="txtCBRecamaras" type="checkbox" style={{ fontSize:13, fontWeight:'bold'}}/>
                        Recamaras
                    </Label>
                    </Row>
                <Row style={{paddingLeft:20}}>      
                        <Input bsSize='sm' id="txtRecamaras" style={{fontSize:12, width:160, backgroundColor:'RGBA(255,255,255,.3)'}} type="select">
                        {  [{reca:'1+'},{reca:'2+'},{reca:'3+'},{reca:'4+'},{reca:'5+'}].map((pos,index) => (
                                        <option key={index} value={pos.reca}>{pos.reca}</option>
                                        ))}
                        </Input>
                </Row>
                <br/>
                <div style={{textAlign:'center', paddingRight:25}}>
                            <Button size='sm' onClick={this.getCasas.bind(this)} style={{fontSize:13}} outline color='primary'> Buscar</Button>
                </div>
                    <br/>
                   </div>
                <br/>

                

                <div style={{ width:'100%',height:500 }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: 'AIzaSyB_eF_QESksS9uxSVM0znVswQ2TdaRq0gk', 
                                language: 'es'
                            }}
                            defaultCenter={this.props.center}
                            defaultZoom={this.props.zoom}
                            options={{disableDefaultUI: true,
                                mapTypeControl: true,
                                streetViewControl: true,
                            }}
                            >

                                
                            
                            { this.state.casasLs.length>0?this.state.casasLs.map(casas =>(
                                <Marker
                                key={casas.id}
                                lat={casas.lat}
                                lng={casas.lon}
                                name={casas.colonia}
                                valor={casas.valor}
                                color="red"
                                foto={casas.fotot}
                                getDetalle={this.getDetalle.bind(this,casas)}
                                
                                />
                                 )):''}


                        </GoogleMapReact>
				</div>
                <br/><br/>
               {

               this.state.detalle===false?
                <div>
                    {                       
                        this.state.casasLs.length>0?this.state.casasLs.map(casas =>(
                            <div  key={casas.id} onClick={this.getDetalle.bind(this,casas)} className="card p-3 rounded text-muted bg-light border border-light text-center" style={{ width:'300px',cursor:'pointer', height:'450px',float:'left', margin:10}}>
                                        <img src={casas.fotot} width='100%' alt={"presentacion_detalle_card_"+casas.id} loading="lazy"/>       
                                <div style={{textAlign:'center',paddingTop:10 ,fontFamily:'Lote'}}>
                                    <h4 style={{color:'black'}}>{casas.colonia}</h4>
                                    <h5 style={{color:'black'}}>{casas.municipio}, {casas.estado}</h5>
                                    <h4 style={{color:'black'}}><NumberFormat value={casas.valor} displayType={'text'} thousandSeparator={true} prefix={'$'}/></h4>
                                    <p className="card-text text-justify">{casas.descripcion}</p>
                                   
                                                         
                                </div>
                                <div style={{ position:'absolute',height:40,width:'100%',left:0,bottom:0,backgroundColor:'RGBA(0,0,0,0.3)'}}>
                                    <InputGroup style={{paddingLeft:20,paddingTop:7, verticalAlign:'middle'}}>
                                <i className="fa fas fa-bed" style={{color:'white',fontSize:'18px',paddingTop:5}}></i><p style={{color:'white',fontSize:12}} className="card-text text-center">{casas.recamaras}</p>
                                <span style={spacer4}/>
                                <i className="fa fas fa-bath" style={{color:'white',fontSize:'18px',paddingTop:5}}></i><p style={{color:'white',fontSize:12}} className="card-text text-center">{casas.totalbanos}</p>
                                <span style={spacer4}/>
                                <i className="fa fas fa-home" style={{color:'white',fontSize:'18px',paddingTop:5}}></i><p style={{color:'white',fontSize:12}} className="card-text text-center">{casas.areaconstruccion} m2</p>
                                </InputGroup>
                                    </div>  
                            </div>
                            )) :''
                    }
                    
                </div>
                :
                
                    <div >
                        <div style={{textAlign:'center'}}>
                            <Button outline color='secondary' onClick={this.goRegresa.bind(this)}>Regresar</Button>
                        </div>
                        <br/>
                        <div style={{textAlign:'center',fontWeight:'bold', fontSize:20}}>
                            <div style={{display:'flex' , flexWrap:'wrap', flexDirection:'row', paddingTop:5, paddingLeft:5}}>
                                {
                                    this.state.fotosLs.length>0?
                                    <div style={{width:600,marginLeft:'auto',
                                marginRight:'auto'}}>
                                <CarruselDetalle fotosLs={this.state.fotosLs}/>
                               
                                </div>
                                    
                                    //<img style={{width:640,height:480}} alt="" src={this.state.fotosLs[this.state.curindex].fotot}/>
                                    :''
                                }
                            </div>
                        </div>
                                    {/* <div className="container fluid" style={{paddingLeft:100,paddingRight:100}}>
                                    {
                                            this.state.fotosLs.length>0?
                                            this.state.fotosLs.map((pos,index) => (
                                                <img key={index} onClick={this.cambiaIndex.bind(this,index)} src={pos.fotot} alt='' style={{width:100,height:60, paddingLeft:10}}/>
                                                )):''

                                    }
                                        </div>
                                    */}
                        
                            <div className="container fluid" style={{paddingLeft:100,paddingRight:100}}>
                            <ConsultaPropiedad curdatosLs={this.state.curcasa} />
                            </div>
                    </div>
               
               }
                
                
                      
                <br/><br/><br/><br/><br/>	
               
            </div>
        )
     }
} export default Propiedades