import React, {useState} from 'react';
import './Marker.css';
import NumberFormat from 'react-number-format';
import {Card,CardImg,CardImgOverlay,Label} from 'reactstrap';


const Marker = (props: any) => {
  const [count, setCount] = useState(false);
 
  const cambia=()=>{
    setCount(!count)
    }
    const { color, valor, name } = props;
    return (
      <div onClick={props.getDetalle} onMouseOut={cambia.bind(this)} onMouseOver={cambia.bind(this)}>
        {count===true?
        
          <Card body color="secondary" style={{ width:'150px',height:'150px',  position:'relative',top:-220, left:-70}}>
          <CardImg top width="100%" src={props.foto} alt="Card image cap" />
          <CardImgOverlay style={{height:'30px',textAlign:'center', position:'relative', top:0, backgroundColor:'RGBA(255,255,255,1'}}>
                               
                                  <Label >{props.name}</Label>
                                  <br/>
                                  <Label ><NumberFormat value={valor} displayType={'text'} thousandSeparator={true} prefix={'$'}/></Label>
                               
          </CardImgOverlay>
          
          </Card>
        
       :''
        }
        <div
          className="pin bounce"
          style={{ backgroundColor: color, cursor: 'pointer' }}
          title={name}
        >
          
          
        </div>
       
        <div className="pulse" />
      </div>
    );
  };

  export default Marker;