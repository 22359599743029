import React, { Component } from 'react';
import { Label, Row, Col} from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import NumberFormat from 'react-number-format';

export class ConsultaPropiedad extends Component{

    static defaultProps = {
        center: {lat: 25.681685, lng: -100.316153}, 
        zoom: 11
     }

    state={
        center: {lat: 25.681685, lng: -100.316153},
        myurl:'',
        mydataurl:'',
        mywaturl:'' 
    }
   
     


       myfu = () =>{
           console.log(this.props.curdatosLs.lat);
           let lati = this.props.curdatosLs.lat;
           let longi = this.props.curdatosLs.lon;
           console.log(this.props.curdatosLs.lon);
        this.setState({
center: {lat: Number(lati), lng: Number(longi)}
        }); 
        console.log(this.state.center)
       } 


        


    

componentDidMount(){
   this.myfu();
this.setState({myurl:"https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fadhome.mx%2F%3Fid%3D"+this.props.curdatosLs.id+"&amp;src=sdkpreparse"});
this.setState({mydataurl:"https://adhome.mx/?id="+this.props.curdatosLs.id});
this.setState({mywaturl:"https://api.whatsapp.com/send?text=Adhome%20le%20comparte%20esta%20propiedad%20:%20https://adhome.mx/?id="+this.props.curdatosLs.id});

}
   

    render(){
       
       const space4={margin:4}
        return(
            
            <div className="container fluid" style={{fontSize:14, fontFamily:'Raleway:200'}}>
                 <br/>
                <div style={{textAlign:'center',fontWeight:'bold', fontSize:20}}>
                <br/>
                <Label onClick={this.myfu.bind(this)} >DATOS DE LA PROPIEDAD</Label>
                <br/>
                </div>
                <div>
                <p style={{textAlign:'center',fontSize:20}}>{this.props.curdatosLs.colonia}</p>
                <p style={{textAlign:'center',fontSize:16}}>{this.props.curdatosLs.municipio}, {this.props.curdatosLs.estado} </p>
                <p style={{textAlign:'center',fontSize:14}}>{this.props.curdatosLs.descripcion}</p>
                <p style={{textAlign:'center',fontSize:20,fontFamily:'Lato'}}><NumberFormat value={this.props.curdatosLs.valor} displayType={'text'} thousandSeparator={true} prefix={'$'}/></p>
                
                <div class="fb-share-button" data-href={this.state.mydataurl} data-layout="button" data-size="small"><img width={30} src="https://adhome.mx/services/adhome_imagenes/icoface.png" alt='adHome' /><a target="_blank" href={this.state.myurl} class="fb-xfbml-parse-ignore"> Compartir en Facebook</a></div>
                
                <div><img width={30} src="https://adhome.mx/services/adhome_imagenes/icowhats.png" alt='adHome' /><a href={this.state.mywaturl}> Compartir en whatsapp</a></div>
               
                <a style={{color:'white',fontSize:16, fontFamily:'Lato' }} href={this.state.mywaturl}><i className="fa fab fa-whatsapp" ></i><span style={space4} />Compartir en Whatsapp</a>
                <br/><br/>
                                    <div >
                                    <p style={{textAlign:'justify',fontSize:14,fontFamily:'Lato'}}>
                                    {this.props.curdatosLs.descripcionlarga}
                                    </p>
                                    </div>
                                
                                
                <br/>
                
               
                </div>
                <br/>
                <Row>
                    <Col sm={4}>
                    <pre style={{fontSize:14, fontFamily:'Lato'}}>
                Área de Terreno (Mts) : {this.props.curdatosLs.areaterreno}<br/>
                Área de Construcción (Mts) : {this.props.curdatosLs.areaconstruccion}<br/>
                Frente (Mts) : {this.props.curdatosLs.frente}<br/>
                Fondo (Mts) : {this.props.curdatosLs.fondo}<br/><br/>
                
                </pre>
                </Col>
                <Col sm={4}>
                <pre style={{fontSize:14, fontFamily:'Lato'}}>
                   
                {
                    this.props.curdatosLs.banospn>0?
                    <span>Cant. de Baños 1er. Nivel: {this.props.curdatosLs.banospn}<br/></span>:''
                }
                {
                    this.props.curdatosLs.banossn>0?
                    <span>Cant. de Baños 2do. Nivel: {this.props.curdatosLs.banossn}<br/></span>:''
                }
                {
                    this.props.curdatosLs.banostn>0?
                    <span>Cant. de Baños 3er. Nivel: {this.props.curdatosLs.banostn}<br/></span>:''
                }
                </pre>
                    </Col>
                    <Col sm={4}>
                    <pre style={{fontSize:14, fontFamily:'Lato'}}>
               
                    Equipamiento :<br/>
                {
                    this.props.curdatosLs.cocinaintegral==='s'?
                    <span style={{paddingLeft:20}}>Cocina Integral   {this.props.curdatosLs.cocinaintegral_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.lavanderia==='s'?
                    <span style={{paddingLeft:20}}>Lavanderia  {this.props.curdatosLs.lavanderia_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.cochera==='s'?
                    <span style={{paddingLeft:20}}>Cochera  {this.props.curdatosLs.cochera_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.porton==='s'?
                    <span style={{paddingLeft:20}}>Portón  {this.props.curdatosLs.porton_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.roperia==='s'?
                    <span style={{paddingLeft:20}}>Ropería  {this.props.curdatosLs.roperia_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.vestidor==='s'?
                    <span style={{paddingLeft:20}}>Vestidor  {this.props.curdatosLs.vestidor_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.airelavado==='s'?
                    <span style={{paddingLeft:20}}>Aire lavado  {this.props.curdatosLs.airelavado_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.aireacondicionado==='s'?
                    <span style={{paddingLeft:20}}>Aire acondicionado  {this.props.curdatosLs.aireacondicionado_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.transsumiluz==='s'?
                    <span style={{paddingLeft:20}}>Transformador de suministro de luz  {this.props.curdatosLs.transsumiluz_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.abanicostecho>0?
                    <span style={{paddingLeft:20}}>Abanicos de techo  {this.props.curdatosLs.abanicostecho}  {this.props.curdatosLs.abanicostecho_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.msutonelada>0?
                    <span style={{paddingLeft:20}}>Minisplit 1T  {this.props.curdatosLs.msutonelada}  {this.props.curdatosLs.msutonelada_}<br/></span>:''
                }
                 {
                    this.props.curdatosLs.msdtoneladas>0?
                    <span style={{paddingLeft:20}}>Minisplit 2T  {this.props.curdatosLs.msdtoneladas}  {this.props.curdatosLs.msdtoneladas_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.terrazafrente>0?
                    <span style={{paddingLeft:20}}>Mts. Terraza del Frente  {this.props.curdatosLs.terrazafrente}  {this.props.curdatosLs.terrazafrente_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.protectorespa==='s'?
                    <span style={{paddingLeft:20}}>Protectores P.A.  {this.props.curdatosLs.protectorespa_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.protectorespb==='s'?
                    <span style={{paddingLeft:20}}>Protectores P.B.  {this.props.curdatosLs.protectorespb_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.alberca==='s'?
                    <span style={{paddingLeft:20}}>Alberca  {this.props.curdatosLs.alberca_}<br/></span>:''
                }
                {
                    this.props.curdatosLs.tipopisopa !==''?
                    <span style={{paddingLeft:20}}>Tipo Piso P.A.  {this.props.curdatosLs.tipopisopa}<br/></span>:''
                }
                 {
                    this.props.curdatosLs.tipopisopb !==''?
                    <span style={{paddingLeft:20}}>Tipo Piso P.B.  {this.props.curdatosLs.tipopisopb}<br/></span>:''
                }
                 {
                    this.props.curdatosLs.tipopisoext !==''?
                    <span style={{paddingLeft:20}}>Tipo Piso Exteriores  {this.props.curdatosLs.tipopisoext}<br/></span>:''
                }
                </pre>
                </Col>
                </Row>
                <br/>
                <br/>
                <div style={{width:'100%',height:'300px'}}>
                            <GoogleMapReact id="mymap"
                            bootstrapURLKeys={{
                               // key: 'AIzaSyBixQeRWGTe40laWtG6AV8M1rMcRebwg_o', 
                               key: 'AIzaSyB_eF_QESksS9uxSVM0znVswQ2TdaRq0gk',
                                language: 'es'
                            }}
                            defaultCenter={this.props.center}
                            center = {this.state.center}
                            defaultZoom={this.props.zoom}
                            options={{disableDefaultUI: true,
                                mapTypeControl: true,
                                streetViewControl: true,
                                }}
                            >
                               
                                <Marker
                                lat={this.props.curdatosLs.lat}
                                lng={this.props.curdatosLs.lon}
                                name={this.props.curdatosLs.colonia}
                                color="red"
                                
                                />
                                 
                            </GoogleMapReact>
                            </div>
                        <br/>
                        <br/>

                
                
                
                
            
                
                <br/><br/><br/><br/><br/>
            </div>
        )
    }
}
export default ConsultaPropiedad