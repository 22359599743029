import React, { Component } from 'react';
import { Jumbotron } from 'reactstrap';

export class Creditos extends Component{
    render(){
        
        return(
            <div className="container" style={{textAlign:"center",paddingLeft:'9%',paddingRight:'9%'}}>
            <Jumbotron style={{backgroundColor:'white', textAlign:"left"}}>
                         <h1 className="display-6">Créditos </h1> <br/>
                         <img hidden={false} src="https://adhome.mx/services/adhome_imagenes/logo_infonavit.jpg" alt='adHome' /><br/><br/>
                         <p className="lead" style={{fontSize:14,textAlign:"justify"}}>El Infonavit te otorga un crédito para que compres tu vivienda, que te ofrezca las mejores condiciones de calidad, ubicación y precio, que eliges libremente, de acuerdo a tus necesidades y posibilidades.<br/><br/>

Para solicitar tu crédito Infonavit es necesario:<br/><br/>

- Participar en el taller de orientación Saber para decidir. La constancia tiene vigencia de un año y es gratuita.<br/>
- Presentar solicitud de crédito con los datos requeridos, incluyendo los números telefónicos de dos familiares o amigos que te conozcan, los cuales serán validados por el Infonavit previo al ejercicio del crédito.<br/>
- Contar con una relación laboral vigente.<br/>
- Que tu patrón realice sus aportaciones patronales del 5% de tu Salario Diario Integrado.<br/>
- Cumplir con la puntuación mínima requerida de 116 puntos; los cuales se determinan con base en tu edad, salario, Saldo de la Subcuenta de Vivienda (SSV) y el número de los últimos bimestres de cotización continua.<br/>
- Tener a la mano la información para la Consulta para tu Evaluación Integral Infonavit<br/><br/>


Requisitos:<br/><br/>

-Teléfono celular:<br/>
-Correo electrónico:<br/>
-Domicilio actual (cp. estado, municipio, colonia, calle, núm. exterior, núm. interior,<br/>
-Información de otros créditos que tienes: Tarjeta de Crédito (últimos 4 dígitos de los 16 que tiene el plástico)<br/><br/><br/>
 

  Nosotros te asesoramos para el trámite de tu crédito.</p>
                        <br/>
                        <br/>
                         <img hidden={false} src="https://adhome.mx/services/adhome_imagenes/logo_infonavittotal.jpg" alt='adHome' /><br/><br/>
                         <p className="lead" style={{fontSize:14,textAlign:"justify"}}>El Instituto te brinda esta variante de crédito aprovechando tu máxima capacidad crediticia, integrando el crédito del Infonavit, el SSV (Saldo de la Subcuenta de Vivienda) y el crédito de una entidad financiera.<br/><br/>
Para solicitar tu crédito Infonavit Total es necesario:<br/><br/>

- Tener ingresos menores a $21,655.74 (menor a 11 VSMD).<br/>
- Cumplir con la puntuación mínima requerida de 116 puntos al momento de la precalificación.<br/>
- Tener entre 18 años y 64 años, 11 meses de edad.<br/>
- No haber tenido un crédito del Infonavit.<br/>
- Participar en el taller de orientación Saber para decidir.<br/>
- La constancia tiene vigencia de un año y es gratuita.<br/>
- Presentar solicitud de crédito con los datos requeridos, incluyendo los números telefónicos celulares de dos familiares o amigos que te conozcan.<br/>
- Sólo para crédito individual.<br/>
- Sólo para adquisición de vivienda nueva o usada.<br/>
- El valor de la vivienda que elijas no debe rebasar los $689,045 (350 VSMDVDF).<br/>
Elegir una paquete de Ecotecnologias para su vivienda.<br/><br/>
Cumpliendo los requisitos anteriores, se podrá obtener un crédito hasta por el 95% del valor de la operación y un monto máximo de hasta $554,651.00</p>

<br/>
<br/>
                         <img hidden={false} src="https://adhome.mx/services/adhome_imagenes/logo_cofinavit.jpg" alt='adHome' /><br/><br/>
                         <p className="lead" style={{fontSize:14,textAlign:"justify"}}>El instituto te brinda el crédito Cofinavit aprovechando tu máxima capacidad crediticia, es un crédito otorgado por el Infonavit, conjuntamente con un banco o Sociedades Financieras de Objeto Limitado (Sofol) y se utiliza el Saldo de la Subcuenta de Vivienda (SSV) para ampliar tu capacidad de compra. El crédito otorgado por el Infonavit se paga con los descuentos que te realice tu patrón.<br/><br/>

Para solicitar tu crédito Cofinavit es necesario:<br/><br/>

- Tener ingresos menores a $21,655.70 (menor a 11 VSMD).<br/>
- Ser derechohabiente del Infonavit con relación laboral vigente.<br/>
- Cumplir con la puntuación mínima requerida de 116 puntos al momento de la precalificación del Infonavit.<br/>
- No haber tenido un crédito del Infonavit.<br/>
- Participar en el taller de orientación Saber para decidir. La constancia tiene vigencia de un año y es gratuita.<br/>
- Presentar solicitud de crédito con los datos requeridos, incluyendo los números telefónicos de dos familiares o amigos que te conozcan.<br/>
- Aplicar el crédito para comprar una vivienda nueva o usada.<br/>
- Obtener la aprobación del crédito del banco o Sofol.</p>
<br/>
<br/>
                         <img hidden={false} src="https://adhome.mx/services/adhome_imagenes/logo_apoyoinfonavit.jpg" alt='adHome' /><br/><br/>
                         <p className="lead" style={{fontSize:14,textAlign:"justify"}}>Es un crédito otorgado por un banco o una Sociedad Financiera de Objeto Limitado (Sofol) usando tus aportaciones subsecuentes, para amortizar tu crédito. Puedes adquirir o construir una vivienda sin límite en su valor, el Saldo de la Subcuenta de la Vivienda (SSV) queda como garantía de pago, en caso de pérdida de empleo y tus aportaciones patronales, una vez otorgado el crédito, se pueden aplicar como anticipo a capital para reducir el plazo del crédito y pagar menos intereses o para disminuir tu pago mensual.<br/><br/>
Para solicitar tu crédito Apoyo Infonavit es necesario:<br/><br/>

- No tener un crédito del Infonavit vigente.<br/>
- Que el banco o Sofol apruebe tu crédito.<br/>
- Firmar la carta de instrucción irrevocable.<br/>
- Que el crédito otorgado por el banco o Sofol sea destinado para comprar una vivienda o construir en terreno propio.</p>
<br/>
<br/>      
    <img hidden={false} src="https://adhome.mx/services/adhome_imagenes/logo_shf.jpg" alt='adHome' /><br/><br/>
                         <p className="lead" style={{fontSize:14,textAlign:"justify"}}>La Sociedad Hipotecaria Federal (SHF) es una institución financiera que te otorga un crédito para adquirir tu casa por medio de Sociedades Financieras de Objeto Limitado (Sofol). Un crédito SHF te puede financiar hasta el 90% del valor de la casa , también existe la opción de mensualidades fijas en pesos durante toda la vida del crédito .<br/><br/>

Así también podrás hacer pagos anticipados a capital, reduciendo así el plazo del crédito y ahorrar intereses. Existen esquemas especiales para personas que no puedan comprobar ingresos fijos.<br/><br/>

Para solicitar tu crédito SHF es necesario:<br/><br/>

- Ser Mexicano por nacimiento, si eres extranjero deberás comprobar tu calidad migratoria mediante la forma FM-2.<br/>
- Tener entre 25 y 59 años 11 meses.<br/>
- Puedes pertenecer a la economía formal o informal.<br/>
- Tener un arraigo laboral mínimo.<br/>
- Comprobar un arraigo domiciliario mínimo.<br/>
- Tener un buen historial crediticio ante Buro de Crédito.<br/><br/>

ASI MISMO:<br/><br/>

- Tener a la mano la información para la Consulta para tu Evaluación Integral<br/>
Requisitos:<br/><br/>

-teléfono celular.<br/>
-correo electrónico.<br/>
-domicilio actual (c.p., estado, municipio, colonia, calle, núm. exterior, núm. interior,<br/>
información de otros créditos que tienes: Tarjeta de Crédito (últimos 4 dígitos de los 16 que tiene el plástico).</p>
<br/>
<br/>
                         <img hidden={false} src="https://adhome.mx/services/adhome_imagenes/logo_fovisste.jpg" alt='adHome' /><br/><br/>
                         <p className="lead" style={{fontSize:14,textAlign:"justify"}}>El Fovisste otorga un crédito con una cantidad que está determinada por la capacidad de pago individual de cada trabajador. El monto máximo de su crédito depende de sus ingresos, que esto resulta de la multiplicación del monto máximo del descuento permitido por la ley, por el plazo del crédito.<br/><br/>

TIPO DE CREDITO FOVISSSTE:<br/><br/>
TRADICIONAL: es otorgado por medio de sorteo<br/><br/>

PENSIONA2:   todo personal que ya se encuentre PENSIONADO por el FOVISSSTE y no haya ejercido crédito de vivienda con anterioridad, nosotros te lo tramitamos.<br/><br/>

RESPALDA2: no es necesario haber salido sorteado, consiste en un crédito otorgado por una institución financiera, apoyado con el saldo de tu subcuenta del FOVISSSTE y tus cuotas obrero/patronal.<br/><br/>

ALIA2: NO es necesario haber salido sorteado, consiste en un crédito otorgado por una institución financiera y fovissste.<br/><br/>

CONYUGAL:   no necesita sorteo, este crédito te lo otorga el departamento de vivienda, ya autorizado cuentas con el 100% de crédito, y podrás mancomunarlo con tu cónyuge a su crédito de INFONAVIT.<br/><br/>
Para ejercer tu crédito Fovissste es necesario:<br/><br/>

- No haber sido beneficiario de un crédito del Fovissste.<br/>
- Contar con una antigüedad mínima de 18 meses de aportación a la subcuenta de vivienda del sistema de ahorro para el retiro (SAR).<br/>
- Ser trabajador derechohabiente del Fondo en activo</p>
<br/>
<br/>
                         <img hidden={false} src="https://adhome.mx/services/adhome_imagenes/logo_isssteleon.jpg" alt='adHome' /><br/><br/>
                         <p className="lead" style={{fontSize:14,textAlign:"justify"}}>El crédito ISSSTELEON es un beneficio hacia los sujetos activos en el servicio público del estado de Nuevo Leon. El destino del crédito solo aplica en el estado de Nuevo León. El monto máximo que se otorga es de $256,089.60 y el plazo máximo para pagarlo es hasta de 30 años. El crédito Isssteleon se paga mediante nómina quincenalmente y el descuento no puede ser mayor al 30% del salario base de cotización.<br/><br/>

Para solicitar tu crédito Apoyo ISSSTELEON es necesario:<br/><br/>

- Contar con un mínimo de 10 años de cotización.<br/>
- Carecer de vivienda propia.<br/>
- Contar 100 o más puntos de calificación.<br/>
- Comprobante de domicilio.<br/>
- Actas del estado civil.<br/>
- Copia identificación oficial.<br/>
- Comprobante de ingresos (últimos 3 meses).<br/>
- CURP.</p>
                     </Jumbotron>
                     <br/><br/><br/><br/><br/>
        </div>
        )
    }
}
export default Creditos