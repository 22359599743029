import React, { Component } from 'react';

class Nav extends Component {
  render() {
    const space4={margin:4}
    return (
      <nav style={{position:"fixed", top:0}}>
          <ul>
              <li><a style={{color:'white',fontSize:16, fontFamily:'Lato' }} href={'https://api.whatsapp.com/send?phone=+5218117377126&text=Hola,%20vi%20los%20anuncios%20en%20tu%20página%20www.adhome.mx%20y%20necesito%20me%20contactes%20!!'}><i className="fa fab fa-whatsapp" ></i><span style={space4} />8117377126</a></li>      
              <li><a style={{color:'white',fontSize:16 }} href="mailto:ventas@adhome.mx?Subject=Interesado%20en%20la%20compra%20o%20venta%20de%20una%20propiedad"><i className="fa fa fa-envelope"></i> ventas@adhome.mx</a></li>
             
              <li style={{fontSize:14}}><a href="#home" onClick={this.props.showInicio}>Home</a></li>
              <li style={{fontSize:14}}><a href="#acerca" onClick={this.props.showAcerca}>Acerca</a></li>
              <li style={{fontSize:14}}><a href="#creditos" onClick={this.props.showCreditos}>Créditos</a></li>
              <li style={{fontSize:14}}><a href="#contacto" onClick={this.props.showContacto}>Contacto</a></li>
          </ul>
      </nav>
    );
  }
}

export default Nav;
