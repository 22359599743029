import React, { Component } from 'react';
import { Jumbotron } from 'reactstrap';
import pdf from "./aviso.pdf";

export class Bienvenidos extends Component{
    render(){
        
        return(
            <div className="container fluid" style={{textAlign:"center", width:'100%'}}>
            <Jumbotron>
                <h1 className="display-6">Bienvenidos a nuestra promotora Inmobiliaria </h1> <br/>
                <a style={{cursor:'pointer'}} href={pdf} target="_blank" rel="noreferrer">Aviso de privacidad</a>
                
                <br/><br/>
                <p className="lead" style={{fontSize:14,paddingLeft:'9%',paddingRight:'9%',textAlign:"justify"}}>Somos una empresa dedicada a Soluciones Inmobiliarias, Tanto para Familias como a empresas, Brindamos asesoría personalizada para comprar y/o vender Su Casa y Servicio Profesional de Venta para empresas del Noreste de la región. <br/><br/>

Nuestra experiencia cuenta con 14 años en el ramo inmobiliario, ofreciendo soluciones en la Venta de viviendas de segmentos Económico, Interés Social, Interés Medio, Media y Residencial a nuestros clientes; nuestro objetivo siempre será brindar Soluciones para Familias e Inversionistas con una gran pasión y calidad por el trabajo.<br/><br/>

Aquí podrá encontrar una Solución a sus necesidades Inmobiliarias: Asesoría Gratuita, Venta y Compra de Bienes Raíces, Venta de Desarrollos Inmobiliarios, además de Estudios de Mercado, Consultoría Comercial, Integración de Fuerzas de Venta, Formación de Vendedores.<br/><br/>

Realizamos gestorías de todos los créditos en el mercado: Infonavit Tradicional, Fssste, Isssteleon, SHF, Pemex, Cofinanciamiento, Infonavit Total, Segundos Créditos de Infonavit, Traspasos de créditos, Recuperación de Subcuenta de vivienda, Propiedades intestadas para su venta. No importa si la casa que busca es nueva o usada nosotros le ayudamos a tomar la mejor decisión a través de presupuestos de ventas que incluyen todo lo que se debe considerar al momento de una compra: Impuestos, Gastos Notariales, Ecotecnologias (en caso de que aplique), Honorarios Notariales, etc.<br/><br/>

Ofrecemos asesoría de venta para casas usadas (realizamos opiniones de valor) para establecer las condiciones idóneas para su comercialización; generamos clientes para su propiedad, integramos expediente de propiedad, de vendedor, así como de comprador, Escritura de propiedad ante Notario Público y gestionamos el pago de su propiedad, todo con el Profesionalismo que nos caracteriza.</p>
               <br/>
               <img hidden={window.innerWidth<580?true:false} src="https://adhome.mx/services/adhome_imagenes/bienveimagen.jpg" alt='adHome' />
            </Jumbotron>
            <br/><br/><br/><br/><br/>
        </div>
        )
    }
}
export default Bienvenidos